import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconPrivate from "../../../images/jsIcons/IconPrivate";
import CusButton from "../../mui/CusButton";
import IconTg from "../../../images/jsIcons/IconTg";
import { useDispatch, useSelector } from "react-redux";
import ChatSmile from "./ChatSmile";
import usePremiumClick from "../../../hooks/usePremiumClick";
import { errorToast, infoToast } from "../../mui/Toaster";
import { ENUM_CHAT_MODE, setIsAnonymity, setSendValue } from "../../../store/chatSlice";
import smile from "../../../images/chat/smile.svg";
import TextareaWitchSmile from "../../mui/textarea_witch_smile/TextareaWitchSmile";
import SendPlug from "./SendPlug";
import useTips from "../../../hooks/useTips";

const ChatBottomFixed = lazy(() => import("./ChatBottomFixed"));

export default function ChatSend({
  onChangeAmount,
  tabFree,
  skipPlug,
  onSendMes,
  sendPlug,
  placeholder,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onPremiumClick } = usePremiumClick();

  const isIphone = useSelector((state) => state.deviceSize.isIphone);
  const isMobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);
  const isLsm = useSelector((state) => state.deviceSize.isLsm);
  const isMobile = useSelector((state) => state.deviceSize.isMobile);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);
  const chatActiveMode = useSelector((state) => state.chat.chatActiveMode);
  const isAnonymity = useSelector((state) => state.chat.send.isAnonymity);
  const value = useSelector((state) => state.chat.send.value);

  const { currentToken, amount, setCurrentToken, onTipsMessageClick } = useTips();

  const [openSmilePopup, setOpenSmilePopup] = useState(false);

  const TextAreaStartRef = useRef(null);
  const ChatSendRef = useRef(null);
  const TextareaRef = useRef(null);
  const ChatSendSendRef = useRef(null);
  const blurTextAreaRef = useRef(null);

  const isPrivate = [ENUM_CHAT_MODE.exclusive, ENUM_CHAT_MODE.private].includes(chatActiveMode);
  const anonymityClass = isAnonymity ? "anonymity" : "";

  const titleTipsBtn =
    isMobileHeader && !isLsm
      ? ` ${amount || 0} ${t("tk")}`
      : `${t("Send")} ${amount || 0} ${t("tk")}`;

  useEffect(() => {
    if (value) return;
    dispatch(setSendValue(placeholder || ""));
  }, [placeholder, value]);

  const onAnonymityClick = async () => {
    TextareaRef.current.focus();

    if (isPrivate) {
      if (isFullScreen) await document?.exitFullscreen?.();
      errorToast(t("In private, only personal messages are allowed."));
      return;
    }

    onPremiumClick(() => {
      const text = isAnonymity ? "Private messaging disabled." : "Private messaging enabled.";
      dispatch(setIsAnonymity(!isAnonymity));
      infoToast(t(text));
    });
  };

  // TODO пока реализованно только так что это просто отправка чаевых + отправка сообщения
  const onSend = (e) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    TextareaRef.current.focus();

    // if (TEST_MODE) return;

    onTipsMessageClick(() => {
      // eslint-disable-next-line no-control-regex
      onSendMes(value.replace(new RegExp("\r?\n", "g"), "<br />"), currentToken, isAnonymity);
      dispatch(setSendValue(""));
    });
  };

  const handleScrollToTop = () => window.scrollTo(0, 0);

  const onTextAreaFocus = () => {
    if (!isIphone) return;
    clearTimeout(blurTextAreaRef.current);

    setTimeout(() => {
      handleScrollToTop();
      const root = document.getElementById("root");
      const viewportHeight = visualViewport.height;
      root.style.height = viewportHeight + "px";
      root.style.overflow = "auto";
      root.style.position = "fixed";
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
      ChatSendRef.current.classList.add("fixed-ios");
      ChatSendSendRef.current.style.top = `${
        viewportHeight - ChatSendSendRef.current.clientHeight
      }px`;
      ChatSendSendRef.current.style.bottom = "auto";
      TextareaRef.current.focus();
    }, 240);
  };

  const onTextAreaBlur = () => {
    if (!isIphone) return;

    clearTimeout(blurTextAreaRef.current);
    blurTextAreaRef.current = setTimeout(() => {
      const root = document.getElementById("root");
      root.style.height = "100%";
      root.style.overflow = "auto";
      root.style.position = "static";
      document.body.style.overflow = "auto";
      document.body.style.touchAction = "auto";
      ChatSendRef.current.classList.remove("fixed-ios");
      ChatSendSendRef.current.style.top = "auto";
      ChatSendSendRef.current.style.bottom = "0";

      setTimeout(handleScrollToTop, 40);
    }, 200);
  };

  const onKeyDown = (e) => {
    if (e.key !== "Enter") return;
    if (e.ctrlKey) {
      dispatch(setSendValue(value + "\r\n"));
      console.log("ctrlKey");
      return;
    }
    e.preventDefault();
    onSend();
  };

  return (
    <>
      <div className="chat__send" ref={ChatSendRef}>
        <SendPlug
          onChangeAmount={onChangeAmount}
          currentToken={currentToken}
          setCurrentToken={setCurrentToken}
          skipPlug={skipPlug}
          sendPlug={sendPlug}
        />

        {!tabFree && <label className="chat__send__label">{t("Your comment")}</label>}

        <div
          className="chat__send__send-container"
          onClick={() => {
            TextareaRef?.current?.focus?.();
          }}
        >
          <Suspense>
            <ChatBottomFixed
              ChatSendSendRef={ChatSendSendRef}
              TextareaRef={TextareaRef}
              ChatSendRef={ChatSendRef}
              className="chat__send__send"
            >
              <button
                id="button-anonymity"
                className={`chat__send__anonymity ${anonymityClass}`}
                onClick={onAnonymityClick}
              >
                <button>
                  <IconPrivate />
                </button>
              </button>

              <TextareaWitchSmile
                TextareaRef={TextareaRef}
                rows={1}
                style={{ position: "absolute", opacity: 0 }}
                className="chat__send__write-mes"
                value={value}
                placeholder={t(isMobile ? "Your message" : "Write your message...")}
                onChange={(e) => dispatch(setSendValue(e.target.value))}
                onFocus={onTextAreaFocus}
                onBlur={onTextAreaBlur}
                onKeyDown={onKeyDown}
                onSelectCapture={(e) => {
                  TextAreaStartRef.current = e.target.selectionStart;
                }}
              />

              <button
                className="chat__send__btn-smile"
                onClick={(e) => {
                  e.stopPropagation();
                  TextareaRef.current.focus();
                  setOpenSmilePopup((prev) => !prev);
                }}
              >
                <img src={smile} alt="smile" />
                <ChatSmile
                  TextareaRef={TextareaRef}
                  open={openSmilePopup}
                  setOpen={setOpenSmilePopup}
                  TextAreaStartRef={TextAreaStartRef}
                />
              </button>
              {tabFree || isMobile ? (
                <CusButton type="button" color="red" icon={<IconTg />} onClick={onSend} />
              ) : (
                <CusButton color="red" text={titleTipsBtn} className="tips" onClick={onSend} />
              )}
            </ChatBottomFixed>
          </Suspense>
        </div>
      </div>
    </>
  );
}
