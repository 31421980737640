import React, { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HomeUnderHeader from "./HomeUnderHeader";
import ErrorBoundary from "../mui/error_boundary/ErrorBoundary";
import { useDispatch } from "react-redux";
import { clearBroadcaster, ENUM_STATUS_WINDOW, setStatusWindow } from "../../store/chatSlice";
import ShowContainer from "../mui/ShowContainer";
import HomeContent from "./HomeContent";
import HeaderContainer from "./header/HeaderContainer";
import FiltersDesktopContainer from "./filters/FiltersDesktopContainer";
import JanusState from "../../controls/janus/site/JanusState";

const HomeContentHeader = lazy(() => import("./HomeContentHeader"));
const Footer = lazy(() => import("./Footer"));

export default function HomeMain() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    dispatch(clearBroadcaster());
    dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));
    JanusState.isRedirect = false;
    JanusState.isAwait = false;
  }, []);

  return (
    <main className={`home ${t("currentLang")}`}>
      <ErrorBoundary>
        <HeaderContainer />
      </ErrorBoundary>
      <ErrorBoundary>
        <HomeUnderHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      </ErrorBoundary>
      <div className="home__content">
        <ErrorBoundary>
          <ShowContainer condition={!collapsed}>
            <FiltersDesktopContainer />
          </ShowContainer>
        </ErrorBoundary>
        <ErrorBoundary>
          <div className={`home__content__container ${collapsed ? "collapsed" : ""} `}>
            <Suspense>
              <HomeContentHeader />
            </Suspense>
            <HomeContent collapsed={collapsed} />
          </div>
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Suspense>
          <Footer />
        </Suspense>
      </ErrorBoundary>
    </main>
  );
}
