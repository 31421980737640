import React, { lazy, Suspense, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ChatSend from "./ChatSend";
import { useSelector } from "react-redux";

import { ENUM_STATUS_WINDOW } from "../../../store/chatSlice";
import ShowContainer from "../../mui/ShowContainer";
import useJanusMessage from "../../../hooks/useJanusMessage";

const MessagesJSX = lazy(() => import("./MessagesJSX"));
const ChatPin = lazy(() => import("./ChatPin"));
const NewMessagePlug = lazy(() => import("../../mui/NewMessagePlug"));

export const TYPE_ENUM = {
  date: "date",
  someonesMessage: "someonesMessage",
  myMessage: "myMessage",
  modelMessage: "modelMessage",
  tipped: "tipped",
  lovense: "lovense",
  lovenseConnect: "lovenseConnect",
  vibratotion: "vibratotion",
  switched: "switched",
  system: "system",
  lovenseDisconnect: "lovenseDisconnect",
};

// ChatFree  - главный компонент бесплатного чата
export default function ChatFree({ maxHeight, hideOther }) {
  const { onSendMessage } = useJanusMessage();
  const { t } = useTranslation();
  const isMobile = useSelector((state) => state.deviceSize.isMobile);
  const status = useSelector((state) => state.chat.statusWindow);

  const [showNewMes, setShowNewMes] = useState(false);
  const freeMessages = useRef();

  const makeScrollBottom = (ref) => {
    if (!ref) return;

    ref.scrollTop = ref.scrollHeight;
    setShowNewMes(false);
  };

  const onSendMes = (text, _, onlyBroadcaster) => {
    onSendMessage(text, onlyBroadcaster);
    makeScrollBottom(freeMessages.current);
  };

  const isAvailable = useMemo(() => status !== ENUM_STATUS_WINDOW.offline, [status, isMobile]);
  // const isAvailable = useMemo(() => true, [status]);

  // return JSX
  return (
    <div className="chat__free">
      <Suspense>
        <ChatPin />
      </Suspense>
      {!isAvailable ? (
        <div className="chat__free__empty">
          {t("messages are only available when the broadcasting")}
        </div>
      ) : (
        <>
          <Suspense>
            <MessagesJSX
              maxHeight={maxHeight}
              hideOther={hideOther}
              freeMessages={freeMessages}
              makeScrollBottom={makeScrollBottom}
              setShowNewMes={setShowNewMes}
            />
          </Suspense>

          <ShowContainer condition={showNewMes}>
            <Suspense>
              <NewMessagePlug onClick={() => makeScrollBottom(freeMessages.current)} />
            </Suspense>
          </ShowContainer>
        </>
      )}
      <ChatSend tabFree onSendMes={onSendMes} />
    </div>
  );
}
