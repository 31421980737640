import React from "react";
import { SITE_NAME } from "../common/consts";
import CloseModal from "./CloseModal";

export default function ModalHeader({ icon, title, closeModal }) {
  return (
    <div className="modal__header">
      {icon}
      <h2 className="modal__chapter">{title}</h2>
      <span className="modal__header__or"></span>
      <h2>{SITE_NAME}</h2>
      <CloseModal close={closeModal} />
    </div>
  );
}
