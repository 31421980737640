import { useDispatch } from "react-redux";
import { infoToast } from "../components/mui/Toaster";
import JanusState from "../controls/janus/site/JanusState";
import { ENUM_CHAT_MODE, setMessages } from "../store/chatSlice";

export default function useJanusMessage() {
  const dispatch = useDispatch();

  // onSendMessage - оправка сообщений
  const onSendMessage = (text, senderId) => {
    const clientChat = JanusState.clientChat;
    const isPrivate = [ENUM_CHAT_MODE.exclusive, ENUM_CHAT_MODE.private].includes(
      JanusState.chatActiveMode
    );
    const privateMes = senderId || isPrivate;
    const mesId = JanusState.clientChat?.broadcasterFeed?.id;
    console.log("mesId", mesId);
    console.log("clientChat", clientChat);
    clientChat.localfeed.send(text, privateMes ? [mesId] : []);
  };

  const getHistoryMes = async (clientChat = JanusState.clientChat) => {
    const mes = await clientChat.history();
    const messages = mes.messages;
    console.log("mes");
    if (mes?.data?.message) infoToast(mes.data.message);
    if (messages && messages?.length) dispatch(setMessages(messages));
  };

  return { onSendMessage, getHistoryMes };
}
