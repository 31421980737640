import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import { ENUM_LOCALSTORAGE } from "../components/common/enums";
import usePaidAction from "./usePaidAction";
import { API_CHAT } from "../api/chat";
import { setNewMessages, setTips, setTipsMenu } from "../store/chatSlice";
import { errorToast, successToast } from "../components/mui/Toaster";
import JanusState from "../controls/janus/site/JanusState";
import { TYPE_ENUM } from "../components/chat/chat/ChatFree";
import useJanusMessage from "./useJanusMessage";

export default function useTips() {
  const dispatch = useDispatch();
  const { onTipsMessageClick, onPaidActionClick } = usePaidAction();
  const { onSendMessage } = useJanusMessage();

  const tips = useSelector((state) => state.chat.tips);
  const tipsMenu = useSelector((state) => state.chat.tipsMenu);
  const nickname = useSelector((state) => state.user.user.nickname);
  const name = nickname?.replace("@", "");

  const defaultTips = { amount: tips?.[0]?.value, ...tips[0] } || { amount: 50 };

  const [currentToken, setCurrentToken] = useLocalStorage(
    ENUM_LOCALSTORAGE.lastAmount,
    defaultTips
  );

  const amount = currentToken.amount;

  const getTips = async (chatId) => {
    await API_CHAT.getTips(chatId)
      .then((e) => {
        const tips = e.data.tips?.global || [];
        const tipsMenu = e.data.tips?.user || [];
        dispatch(setTips(tips));
        dispatch(setTipsMenu(tipsMenu));
        console.log("currentToken", currentToken);
        if (!currentToken.amount) setCurrentToken({ amount: tips[0].value, ...tips[0] });
      })
      .catch(errorToast);
  };

  // sendTips - отправка чаевых модели
  const postTips = (info) =>
    onPaidActionClick(info?.amount, async () => {
      const chatId = JanusState.clientChat.chatId;

      try {
        const e = await API_CHAT.postTips(chatId, { nickname: name, ...info });
        console.log(e);

        const message_1 = {
          value: info.amount,
          nick: info.nickname,
          type: TYPE_ENUM.tipped,
        };

        dispatch(setNewMessages(message_1));
        successToast(e.data.message);
      } catch (message_3) {
        return errorToast(message_3);
      }
    });

  // postLovenseTips - отправка чаевых модели ищ lovense
  const postLovenseTips = (info) =>
    onTipsMessageClick(async () => {
      const chatId = JanusState.clientChat.chatId;

      try {
        const res = await API_CHAT.postTips(chatId, info);
        successToast(res.data.message);
      } catch (err) {
        return errorToast(err);
      }
    });

  // отпавка сообщений в действиях ловенса
  const onMessageLovense = ({ amount, message, isPrivate, lovenseId }) => {
    onPaidActionClick(amount, () => {
      if (message) onSendMessage(message, isPrivate);

      postLovenseTips({
        amount,
        lovense: { id: lovenseId, message, nickname: name },
      });
    });
  };

  // отпавка сообщений в чаевых
  const onTipsMessagae = (value, message, onlyBroadcaster) => {
    onPaidActionClick(value, () => {
      if (message) onSendMessage(message, onlyBroadcaster);
      if (value) postTips({ amount: value });
    });
  };

  // отпавка сообщений действий в чаевых
  const onActionsTips = (value, id, text) => {
    onTipsMessageClick(() => {
      onPaidActionClick(value, () => setCurrentToken({ amount: value }));
      postTips({ amount: value, id });
      if (text) onSendMessage(text);
    });
  };

  return {
    currentToken,
    tips,
    amount,
    tipsMenu,
    setCurrentToken,
    postLovenseTips,
    postTips,
    onTipsMessageClick,
    getTips,
    onMessageLovense,
    onActionsTips,
    onTipsMessagae,
  };
}
