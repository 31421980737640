import React, { useEffect, useState } from "react";
import IconWarning from "../../../images/jsIcons/IconWarning";
import { useTranslation } from "react-i18next";
import "./error-modal.scss";
import CloseModal from "../../mui/CloseModal";
import ModalTemplate from "../modal/modal_template/ModalTemplate";

export default function ErrorModal({ reload }) {
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowError(true);
  }, []);

  return (
    <ModalTemplate className={"error"} isOpen={showError} closeModal={() => setShowError(false)}>
      <div className="error-modal__content">
        <CloseModal close={() => setShowError(false)} />

        <div className="error-boundary__children">
          <div className="error-block-plug">
            <IconWarning />
            <span className="error-block-plug__span">
              {t("Sorry, failed to load data. Refresh the page or click ")}{" "}
              <span className="error-block-plug__refresh" onClick={reload}>
                “{t("Refresh")}”
              </span>
            </span>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
}
