import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import ShowContainer from "../../../mui/ShowContainer";
import CloseModal from "../../../mui/CloseModal";
import ErrorBoundary from "../../../mui/error_boundary/ErrorBoundary";
import "./modal-template.scss";
import ModalHeader from "../../../mui/ModalHeader";

export default function ModalTemplate({
  title,
  titleIcon,
  children,
  isOpen,
  closeModal,
  className,
  id,
  hideClose,
  isModalHeader,
  onRequestClose,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      id={id}
      ariaHideApp={false}
      className={`template-modal ${className}-modal`}
      isOpen={isOpen}
      onRequestClose={onRequestClose || closeModal}
    >
      {isModalHeader ? (
        <ModalHeader icon={titleIcon} title={title} closeModal={closeModal} />
      ) : (
        <div className={`${className}__header template-modal__header `}>
          {titleIcon}
          <h2> {typeof title === "string" ? t(title) : title}</h2>
          <ShowContainer condition={!hideClose}>
            <CloseModal close={closeModal} />
          </ShowContainer>
        </div>
      )}
      <ErrorBoundary>
        <div className={`${className}__content template-modal__content `}>{children}</div>
      </ErrorBoundary>
    </Modal>
  );
}
