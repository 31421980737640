import { ENUM_LOCALSTORAGE } from "../../../components/common/enums";

/**
 * глобальный стор януса
 */
export default class JanusState {
  static audioRef = undefined; // useRef
  static videoRef = undefined; // useRef

  // - текущий статус экрана чата
  static statusWindow = "Loading"; // ENUM_STATUS_WINDOW
  // - флаг стоит ли дожидаться чего либо
  static isAwait = false;
  // флаг покинул ли пользователь чат модели
  static isLeave = false;

  // флаг происходит ли сейчас редирект
  static isRedirect = false;
  // номер последнего запроса
  static requestId = 0;
  // флаг успешно ли соединение
  static isSuccessInit = false;
  // флаг проигрывается ли видео
  static isPlayVideo = false;
  // статус видео ENUM_READY_STATE
  static readyState = undefined;
  // флаг - находится ли инициализация януса в процессе
  static inInitJanus = false;
  // флаг - находится ли разрушение януса в процессе
  static inDestroy = false;
  // режим чата для текущего пользователя
  static participantMode = "";
  // режим чата для комнаты
  static roomMode = "";
  // реежим чата для комнаты
  static chatActiveMode;

  // флаг будет ли восстановлен  прошлый режим чата
  /**
   * @param {boolean} val
   */
  static set isRestore(val) {
    localStorage.setItem(ENUM_LOCALSTORAGE.isRestore, val);
  }

  static get isRestore() {
    return localStorage.getItem(ENUM_LOCALSTORAGE.isRestore) == "true";
  }

  static clientChat = {}; // KronaChatBase
  static statuses = {}; // JanusStatuses
  static insideSite = {}; // JanusInside

  /**
   * @get
   */

  static get awaitOrLeave() {
    return JanusState.isAwait || JanusState.isLeave;
  }
  static get inProcess() {
    return JanusState.inDestroy || JanusState.inInitJanus;
  }

  /**
   * @script
   */

  static disconnect() {
    JanusState.isAwait = false;
    JanusState.isSuccessInit = false;
    JanusState.inDestroy = false;
    JanusState.inInitJanus = false;
  }

  static initVideoAudio({ videoRef, audioRef }) {
    if (videoRef) JanusState.videoRef = videoRef;
    if (audioRef) JanusState.audioRef = audioRef;
  }

  static initChatSite({ clientChat, janusSite }) {
    JanusState.clientChat = clientChat;
    JanusState.insideSite = janusSite;
  }
}
